/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Main, Footer, Text, Anchor, ResponsiveContext } from "grommet"
import Header from "./header"
import GlobalStyle from "./global-style"
import { Helmet } from "react-helmet"
import { ToastContainer } from "./bases/toast"
import { useTheme } from "../hooks"
import { debug } from "../utils"
let themeShowCount = 0
const Layout = ({ children, pure, loading, ...rest }) => {
  const data = useStaticQuery(graphql`
    query LayoutSiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const theme = useTheme()
  if (themeShowCount === 0) {
    debug("theme: %o", theme.theme)
    themeShowCount++
  }

  return (
    <>
      <GlobalStyle></GlobalStyle>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500"
        />
      </Helmet>
      <ToastContainer></ToastContainer>

      {!pure && <Header siteTitle={data.site.siteMetadata.title}></Header>}
      <Box
        align="center"
        justify="center"
        pad={{ left: "medium", right: "small", vertical: "large" }}
      >
        <Main width={{ max: "xxlarge" }} fill>
          {children}
        </Main>
      </Box>

      {!pure && (
        <Footer align="center" justify="center">
          <Box
            width={{ max: "xxlarge" }}
            fill
            direction="row"
            justify="center"
            pad="medium"
          >
            <Text>
              © {new Date().getFullYear()}, Built with{" "}
              <Anchor href="https://www.gatsbyjs.org">Gatsby</Anchor>
            </Text>
          </Box>
        </Footer>
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
